<template>
  <Loading v-if="processing" />

  <div class="desktop-height mx-4">
    <Form @submit="nextStep" class="p-2" :validation-schema="currentSchema" v-slot="{ errors }" ref="form">
      <div>
        <div v-if="step === false">
          <div class="text-lg">
            <div class="mb-4">
              RapLand allow music supporters to rank the top rappers in their state.
            </div>
            <div class="mb-4 font-black text-xl">Two Account Types:</div>
            <div class="mb-4">
              Artist accounts: Rappers who want to be discovered by rap supporters in
              their state.
            </div>
            <div class="mb-4">
              Community accounts: People who want to discover and support new rappers in
              their state.
            </div>
            <div class="mb-4 font-black text-xl">Voting:</div>
            <div class="mb-4">
              Members of the community will have one vote per week to choose the top
              rapper in their state. Once they submit their vote they will be added to our
              weekly raffle for a chance to win a weekly prize.
            </div>
            <div class="mb-4">
              Every Monday at 12:00 EST we will send the weekly results via email. We will
              also share the results via social media.
            </div>
            <div class="mb-4">RapLand is free for all community members.</div>
            <div class="mb-4">
              RapLand is also free for the first 50 rappers in every state. RapLand will
              cost $200 per year after that.
            </div>
            <div class="mb-4">If you understand how RapLand works select NEXT!</div>
          </div>
        </div>

        <div v-if="step === 0">
          <div class="mt-1 mb-3 text-xl text-center">
            Choose your account type
          </div>

          <div class="text-white grid grid-cols-5 gap-4 mt-8">
            <div class="col-span-4">I am an artist</div>
            <div class="col-span-1 text-right">
              <label class="radio-container">
                <!-- <input type="radio" checked="checked" name="radio"> -->
                <Field type="radio" id="Artist" name="type" v-model="user.type" value="artist" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="mt-1">
            One year free for first the 50 artists in each state.<br />
            $200/Year for following artists.
          </div>

          <div class="text-white grid grid-cols-5 gap-4 mt-8">
            <div class="col-span-4">I am a music supporter</div>
            <div class="col-span-1 text-right">
              <label class="radio-container">
                <Field type="radio" id="Member" class="ml-auto" name="type" v-model="user.type" value="user" />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div class="mt-1">Free forever</div>
          <ErrorMessage name="type" class="text-red-500 text-sm mt-5" />

          <!-- <div class="mt-8 mb-4 grid grid-cols-6 gap-4 mx-6" v-if="step == 0">
                        <div class="col-start-1 col-end-3" v-if="tosStep != 0">
                          <button @click="tosStep--" class="bg-btn rap-btn">
                            previous
                          </button>
                        </div>
                        <div class="col-end-7 col-span-1">
                          <button v-if="tosStep < 2 && tosStep < 1" @click="tosStep++" class="bg-btn rap-btn">
                            Next
                          </button>
                        </div>
                      </div> -->
        </div>

        <div v-if="step === 1">
          <div class="z-10">
            <div class="mt-2 mb-8 text-xl text-center">
              <span class="text-white">1</span> of
              <span class="text-white">{{ totalSteps }}</span> Steps complete
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-1">Name</div>
              <div class="col-span-2">
                <Field type="text" name="member_name" placeholder="Enter Name"
                  class=" w-full placeholder-white form-control focus:outline-none"
                  :class="{ 'is-invalid': errors.member_name }" v-model="user.member_name" />
                <ErrorMessage name="member_name" class="text-red-500 text-sm" />
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-2">URL</div>
              <div class="col-span-2">
                <div class="mt-1 flex">
                  <div class="items-center pointer-events-none pt-1">
                    rap.land/{{ this.user.type == "user" ? "c/" : "" }}
                  </div>
                  <Field type="text" name="url" placeholder="URL"
                    class=" placeholder-white form-control focus:outline-none w-full"
                    :class="{ 'is-invalid': errors.url }" v-model="user.url" />
                </div>
                <ErrorMessage name="url" class="text-red-500 text-sm" />
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-1">Email</div>
              <div class="col-span-2">
                <Field type="email" name="email" placeholder="Email"
                  class=" w-full placeholder-white form-control focus:outline-none"
                  :class="{ 'is-invalid': errors.member_name }" v-model="user.email" />
                <ErrorMessage name="email" class="text-red-500 text-sm" />
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-1">Password</div>
              <div class="col-span-2">
                <Field type="password" name="password" placeholder="Password" autocomplete="false"
                  class=" w-full placeholder-white form-control focus:outline-none"
                  :class="{ 'is-invalid': errors.password }" v-model="user.password" />
                <ErrorMessage name="password" class="text-red-500 text-sm" />
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-1">Password</div>
              <div class="col-span-2">
                <Field type="password" name="confirm_password" placeholder="Confirm Password" autocomplete="false"
                  class=" w-full placeholder-white form-control focus:outline-none"
                  :class="{ 'is-invalid': errors.confirm_password }" v-model="user.confirm_password" />
                <ErrorMessage name="confirm_password" class="text-red-500 text-sm" />
              </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
              <div class="col-span-1 pt-1">State</div>
              <div class="col-span-2  text-white mt-1">
                <Field name="state_public_id" class=" -ml-1 w-full placeholder-white form-control focus:outline-none"
                  v-model="user.state_public_id" as="select" @change="stateCheck()">
                  <option value="">Choose your state</option>
                  <option v-for="state in states" :key="state.id" :value="state.public_id">
                    {{ state.name }}
                  </option>
                </Field>
                <ErrorMessage name="state_public_id" class="text-red-500 text-sm" />
                <!-- {{ states[0].name }} -->
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 2">
          <div class="mt-2 mb-8 text-xl text-center">
            <span class="text-white">2</span> of
            <span class="text-white">{{ totalSteps }}</span> Steps complete
          </div>

          <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
            <div class="col-span-1 pt-1">Picture</div>
            <div class="col-span-2">
              <div class="relative flex w-full mt-2" v-if="previewAvatar">
                <img :src="previewAvatar" alt="" class="w-24 h-24 cursor-pointer" @click="$refs.file.click()" />
                <i class="flex cursor-pointer justify-center items-center bg-opacity-75 fas fa-times text-red-500 text-xl absolute top-0 right-0 w-8 h-8 rounded-full bg-white"
                  @click="deletePreview()"></i>
              </div>
              <div v-else>
                <img src="../../assets/image/default.png" class="w-24 h-24" alt="" @click="$refs.file.click()" />
              </div>
              <!-- <Field
                              type="text"
                              name="member_name"
                              placeholder="Enter Name"
                              class="p-1 w-full placeholder-white form-control"
                              :class="{ 'is-invalid': errors.member_name }"
                              v-model="user.member_name"
                            /> -->
              <input type="file" ref="file" class="hidden" @change="onFileChange" accept="image/*" />
              <ErrorMessage name="member_name" class="text-red-500 text-sm" />
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
            <div class="col-span-1 pt-2">Instagram</div>
            <div class="col-span-2">
              <div class="mt-1 flex">
                <div class="items-center pointer-events-none pt-1">
                  instagram.com/
                </div>
                <Field type="text" name="instagram" placeholder="Enter"
                  class="p-1 placeholder-white form-control focus:outline-none w-full"
                  :class="{ 'is-invalid': errors.instagram }" v-model="user.instagram" />
              </div>
              <ErrorMessage name="instagram" class="text-red-500 text-sm" />
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
            <div class="col-span-1 pt-2">Soundcloud</div>
            <div class="col-span-2">
              <div class="mt-1 flex">
                <div class="items-center pointer-events-none pt-1">
                  soundcloud.com/
                </div>

                <Field type="text" name="Soundcloud" placeholder="Enter"
                  class="p-1 placeholder-white form-control focus:outline-none w-full"
                  :class="{ 'is-invalid': errors.soundcloud }" v-model="user.soundcloud" />
              </div>
              <ErrorMessage name="soundcloud" class="text-red-500 text-sm" />
            </div>
          </div>

          <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
            <div class="col-span-1 pt-2">Youtube</div>
            <div class="col-span-2">
              <div class="mt-1 flex">
                <div class="items-center pointer-events-none pt-1 focus:outline-none">
                  youtube.com/
                </div>
                <Field type="text" name="youtube" placeholder="Enter" class="p-1 placeholder-white form-control w-full"
                  :class="{ 'is-invalid': errors.youtube }" v-model="user.youtube" />
              </div>
              <ErrorMessage name="email" class="text-red-500 text-sm" />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 mb-4 text-sm">
            <div class="col-span-1 pt-2">Payment</div>
            <div class="col-span-2">
              <div class="mt-1 flex">
                <div class="items-center pointer-events-none pt-1 focus:outline-none"
                  v-if="artist_is_free == true">
                  Free for one year!
                </div>
                <div class="items-center pointer-events-none pt-1 focus:outline-none"
                  v-if="artist_is_free == false">
                  $200.00 per year
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="step === 3">
          <div class="mt-2 mb-10 text-xl text-center">
            <span class="text-white">3</span> of <span class="text-white">4</span> Steps
            complete
          </div>
          <div class="text-lg mb-5">
            <p>If someone referred you to RapLand, please enter their r-code below:</p>
          </div>
          <div class="z-10 mb-10">
            <div v-if="user.type === 'artist'" class="grid grid-cols-2 gap-4 mb-4">
              <!-- <div class="col-span-1 pt-2">R-Code</div> -->
              <div class="col-span-2">
                <div class="mt-1 flex">
                  <Field type="text" name="rCodeUsed" placeholder="Optional" autocomplete="false"
                    class="p-1 w-full form-control focus:outline-none rounded bgwhite"
                    :class="{ 'is-invalid': errors.rCodeUsed }" v-model="rCodeUsed" />
                </div>
                <ErrorMessage name="rCodeUsed" class="text-red-500 text-sm" />
              </div>
            </div>
          </div>

          <div class="text-lg">
            <p>
              To protect your identity please verify your email by following the
              instructions that were just sent to
              <span class="text-white">{{ user.email }}</span>.
            </p>
            <p>Check your spam just in case.</p>
            <p class="mt-4">
              If you already verified your email press the next button below.
            </p>
          </div>
        </div>
      </div>

      <!-- <ErrorMessage
                    name="type"
                    class="block lg:w-full sm:w-2/4 text-left text-red-500 text-sm mt-5"
                  /> -->

      <!-- <div class="mt-8 mb-4 text-left"></div> -->

      <div class="mt-8 mb-4 grid gap-x-8 gap-y-4 grid-cols-3">
        <!--  <div class="">
                      <button
                        @click="step--"
                        class="bg-btn rap-btn"
                        v-if="step < 3"
                      >
                        previous
                      </button>
                    </div>
                    <div class="col-start-5 align-content-lg-center">
                      <button type="button" class="font-bold text-white" v-if="step == 3">
                        Resend email
                      </button>
                    </div> -->
        <div class="col-end-7 col-span-1 mb-5">
          <button type="submit" :disabled="processing" v-if="step < 4" class="bg-btn rap-btn">
            Next
          </button>
          <router-link :to="{ name: 'EditProfile' }" v-else class="bg-btn rap-btn">
            Next
          </router-link>
        </div>
      </div>
      <div v-if="step === 1" class="text-center">
        <a class="text-xs text-white underline underline-offset-1 cursor-pointer"
          v-on:click="ShowConditionPopup = true">
          *By signing up you agree to our TOS and Privacy Policy*
        </a>
      </div>
    </Form>

    <div class="absolute z-50 h-full w-full inset-0 bg-black bg-opacity-75" v-if="showCropper">
      <div class="w-10/12 h-4/6 mx-auto relative mt-20">
        <Cropper ref="cropper" :stencil-props="{ aspectRatio: 1 / 1 }" :src="previewAvatar" minWidth="500"
          minHeight="500" :canvas="{
            minHeight: 0,
            minWidth: 0,
            maxHeight: 2048,
            maxWidth: 2048,
          }">
        </Cropper>
        <div class="mt-8 mb-4 text-right">
          <button class="bg-btn rap-btn mr-4" @click="closeCropper()">Cancel</button>
          <button class="bg-btn rap-btn" @click="cropImage()">Select</button>
        </div>
      </div>
    </div>

    <transition name="fade">
      <div class="absolute shadow-lg top-0 right-0 left-0 m-auto w-full h-full z-50 rapbg" v-if="ShowConditionPopup">
        <div class="absolute shadow-lg right-0 left-0 m-auto  w-full h-full p-2 overflow-auto">
          <div class="divide-y divide-black">
            <div class="grid grid-cols-3">
              <img src="../../assets/image/left-arrow.png" alt="" class="header-action-image mr-8 cursor-pointer"
                @click="ShowConditionPopup = false" />
              <h1 class="lg:text-3xl md:text-3xl text-2xl justify-self-center font-boldcom logo text-center mb-4">
                RapLand
              </h1>
            </div>
            <div>
              <p class="mt-4">
                <TermCondition />
              </p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import API from "../../api/http";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import TermCondition from "./TermCondition";

import { mapState } from "vuex";
import Loading from "../Shared/Loading";

export default {
  name: "Signup",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading,
    Cropper,
    TermCondition,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    currentSchema() {
      // if (this.step === 0 && this.tosStep === 0) {
      //   return false;
      // } else if (this.step === 0 && this.tosStep === 1) {
      //   return this.validationSchema[this.step];
      // } else {
      //   return false;
      // }
      if (this.step !== false) {
        return this.validationSchema[this.step];
      } else {
        return [];
      }
    },
    totalSteps() {
      return this.user.type == "user" ? 2 : 4;
    },
    ...mapState({
      signupStep: (state) => state.User.signupStep,
    }),
  },
  watch: {
    signupStep(newValue) {
      if (newValue) {
        if (this.step > 0) {
          this.step--;
        } else if (this.tosStep > 0) {
          this.tosStep--;
        } else {
          this.$router.go(-1);
        }
        this.$store.commit("User/SET_SIGNUP_STEP", false);
      }
    },
  },
  data() {
    const schema = [
      yup.object({
        type: yup
          .string()
          .typeError("You want to register as artist or member?")
          .required("You want to register as artist or member?"),
      }),
      yup.object({
        // email: yup.string().required().email(),
        member_name: yup
          .string()
          .nullable()
          .min(3, "Name length must be greater than 2")
          .max(15, "Name length must be less than 16")
          .required("Please enter your name")
          .typeError("Please enter your name"),
        url: yup
          .string()
          .nullable()
          .min(3, "URL length must be greater than 2")
          .required("Please enter URL")
          .typeError("Please enter URL"),
        // .matches('^[a-z0-9]+(?:-[a-z0-9]+[-])*$',"Please enter valid URL."),
        email: yup
          .string()
          .nullable()
          .typeError("Please enter your email")
          .email("Please enter valid email address")
          .required("Please enter your email"),
        password: yup
          .string()
          .nullable()
          .typeError("Please enter password")
          .required("Please enter password")
          .min(5, "Password must be more than 5 characters"),
        confirm_password: yup
          .string()
          .nullable()
          .required("Please enter password")
          .oneOf([yup.ref("password")], "Passwords must match"),
        state_public_id: yup
          .string()
          .nullable()
          .required("Please select your state")
          .typeError("Please select your state"),
        rCodeUsed: yup.string().nullable().typeError("Please enter refferal Code"),
      }),
      yup.object({
        instagram: yup.string().nullable(),
      }),
    ];

    return {
      user: {
        member_name: null,
        url: null,
        email: null,
        password: null,
        confirm_password: null,
        type: null,
        state_public_id: null,
        instagram: null,
        soundcloud: null,
        youtube: null,
        image: null,
      },
      // user: {
      //   member_name: 'sandeep',
      //   url: 'sandeep',
      //   email: 'sandeep.solanki2010@gmial.com',
      //   password: '123456',
      //   confirm_password: '123456',
      //   type: 'user',
      //   state_public_id: 'KliOvf5IsiHshqw',
      //   instagram: 'insta',
      //   soundcloud: 'soundcloud',
      //   youtube: 'youtube',
      //   image: null
      // },
      rCodeUsed: null,
      step: false,
      processing: false,
      validationSchema: schema,
      apiUrl: process.env.VUE_APP_API_BASE_URL,
      registerError: null,
      registerErrorShow: false,
      states: [],
      previewAvatar: null,
      formValues: {},
      signUpDone: false,
      showCropper: false,
      tosStep: 0,
      artist_count: 0,
      artist_is_free: null,
      lastAvatar: null,
      ShowConditionPopup: false,
    };
  },
  created() {
    this.init();
    // this.step = 0;
  },
  methods: {
    init() {
      API.get("states?status=").then((response) => {
        this.states = response.states;
        this.artist_count = response.artist_count;
        // this.user.state_public_id = this.states[0].public_id;
      });
    },
    register() {
      this.processing = true;
      if (this.lastAvatar) {
        this.user.image = this.lastAvatar;
      }
      var form_data = new FormData();
      for (var key in this.user) {
        form_data.append(key, this.user[key]);
      }
      API.post("register", form_data, { "Content-Type": "multipart/form-data" })
        .then((response) => {
          if (response.user) {
            if (this.user.type == "user") {
              response.user["token"] = response.token;
              response.user["profile_songs"] = response.profile_songs;
              response.user["artist_songs_count"] = response.artist_songs_count;
              API.setUser(response.user);
              this.$router.push({ name: "TopRappers" });
            } else {
              this.signUpDone = true;
              this.step++;
              this.processing = false;
            }
          }
          // this.toast.success(response.message);
          // this.$router.push({name: 'Login'});
        })
        .catch(() => {
          this.processing = false;
        });
    },
    nextStep(values) {
      if (this.step === false) {
        this.step = 0;
      } else if (this.user.type == "user" && this.step == 1) {
        this.register();
      } else if (this.step == 3 && this.rCodeUsed) {
        this.signUpDone = false;
        this.Loading = true;
        this.rcodeStore();
      } else if (this.step == 3 && this.rCodeUsed == ''){
        this.Loading=true;
        this.signUpDone = true;
        this.$router.push({ name: "EditProfile" });

      } else if (this.signUpDone) {
        this.Loading = false;
        this.$router.push({ name: "EditProfile" });
      } else if (this.step < 2) {
        Object.assign(this.formValues, values);
        this.step++;
      } else if (this.step == 2) {
        this.register();
      }

      document.querySelector(".desktop-content").scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      // this.previewImageUrl = URL.createObjectURL(file);
      this.imageToBase64(file);
    },
    imageToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.previewAvatar = reader.result;
        this.showCropper = true;
      };
    },
    deletePreview() {
      this.previewAvatar = null;
    },
    closeCropper() {
      this.showCropper = false;
      this.previewAvatar = null;
      this.$refs.file.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      const { canvas } = this.$refs.cropper.getResult();
      this.previewAvatar = canvas.toDataURL();
      canvas.toBlob(
        (result) => {
          this.lastAvatar = new File([result], "image");
        },
        "image/jpeg",
        0.95
      );
      this.showCropper = false;
      this.$refs.file.value = "";
    },
    stateCheck() {
      var url = "get-artist-count-by-state/" + this.user.state_public_id;
      API.get(url).then((response) => {
        this.artist_is_free = response.is_free;
      });
    },
    rcodeStore() {
      this.processing = true;
      var url = "store-used-rcode";
      var form_data = new FormData();
      form_data.append("rCodeUsed", this.rCodeUsed);
      form_data.append("email", this.user.email);

      API.post(url, form_data).then(() => {
        this.processing = false;
        this.rCodeUsed = null;
        this.signUpDone = true;
        this.$router.push({ name: "EditProfile" });
      }).catch(() => {
        this.processing = false;
      });
    },
  },
};
</script>
